<template>
  <!-- <div class=""> -->
  <Doughnut
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
  <!-- </div> -->
</template>

<script>
import { Doughnut } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "DoughnutChart",
  components: {
    Doughnut,
  },
  props: {
    chartId: {
      type: String,
      default: "doughnut-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartData: {
        labels: ["Analgesicos", "Antibioticos", "Insumos", "Antidepressivos"],
        datasets: [
          {
            backgroundColor: ["#E6E6FA", "#fab281", "#bae0fc", "#cf72e3"],
            data: [40, 20, 80, 10],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "bottom",
          },
        },
      },
    };
  },

  created() {
    this.listar_atendimentos_filial();
  },
  computed: {
    num_saidas() {
      return this.$store.state.relatorios.num_saidas;
    },
  },
  methods: {
    async listar_atendimentos_filial() {
      await this.$store.dispatch("relatorios/num_saidas_por_tipo");
      var titulo = [];
      var valor = [];
    
      for (let i = 0; i < this.num_saidas.length; i++) {
        titulo.push(this.num_saidas[i].nome);
        valor.push(this.num_saidas[i].total);
      }
      var novosDados = {
        labels: titulo,
        datasets: [
          {
            backgroundColor: [
              "#E6E6FA", //roxo
              "#fab281", //laranja
              "#bae0fc", //azul
              "#cf72e3", //lilas
              "#4682af", // azul escuro
              "#eb97d6", // rosa escuro
              "#97ebac", // verde escuro
              "#ffffa2", //amarelo
              "#eec1d5",
            ],
            data: valor,
          },
        ],
      };
      this.chartData = novosDados;
    },
  },
};
</script>
